import { effect, inject, Signal } from '@angular/core';
import { PwDrawerService } from '../pw-drawer.service';

export function useRegisterDrawer(name: string, ...reactTo: Signal<unknown>[]) {
  const pwDrawerService = inject(PwDrawerService);
  effect(
    () => {
      pwDrawerService.registerDrawer(
        name,
        extractSidebarString(window.location.href),
      );
      reactTo.forEach(($) => $());
    },
    { allowSignalWrites: true },
  );
}
function extractSidebarString(url: string) {
  // Regular expression to match the (sidebar:) part
  const match = url.match(/\(sidebar:(.*?)\)/);

  if (match) {
    return match[1]; // The captured group contains the sidebar string
  } else {
    return url; // No (sidebar:) part found
  }
}
